<template>
  <el-container>
    <!-- 导航栏 -->
    <el-header class="flex" :style="$store.state.displayArea.top">
      <div v-title :data-title="$t('message.about.title')"></div>
      <div class="flex" :style="$store.state.displayArea.top">
        <!-- <div class="logo">
          <img class="img" src="../assets/navigationbar/logo.png" alt="" />
        </div> -->
        <!-- tab -->
        <div class="tab prohibitCopy">
          <div class="tab_box" v-for="(item) in tabArray" :key="item.name" @click="changeTab(item)">
            <div :class="$store.state.navId == item.id ? 'tab_color' : ''">
              {{ item.name }}
            </div>
          </div>
        </div>
        <!-- tab结束 -->
        <div class="bottom flex prohibitCopy" style="justify-content: space-between">
          <div class="bottom_box">
            <el-dropdown @command="handleCommand" :hide-on-click="false" trigger="click">
              <span class="el-dropdown-link flex">
                <img class="img" src="../assets/navigationbar/icon.png" alt="" />
                <div>{{ $store.state.userInfo.nickname }}</div>
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown" style="text-align: center">
                <el-dropdown-item class="flex" command="1">
                  <div class="flex_wid">
                    {{ $t('message.PTTbuttondisplay') }}
                  </div>
                  <el-switch style="margin-left: 15px" v-model="userState.isTalkButton" active-color="var(--main-color)"
                    :inactive-value="0" :active-value="1" inactive-color="#838383">
                  </el-switch>
                </el-dropdown-item>
                <!-- <el-dropdown-item class="flex" command="2">
                  <div class="flex_wid">
                    {{ $t('message.SOSalarmsound') }}
                  </div>
                  <el-switch style="margin-left: 15px" v-model="userState.sosVoice" active-color="var(--main-color)"
                    :inactive-value="0" :active-value="1" inactive-color="#838383">
                  </el-switch>
                </el-dropdown-item>
                <el-dropdown-item class="flex" command="3">
                  <div class="flex_wid">{{ $t('message.Fencealarmsound') }}</div><el-switch style="margin-left: 15px"
                    v-model="userState.enclosureVoice" active-color="var(--main-color)" inactive-color="#838383" :inactive-value="0"
                    :active-value="1">
                  </el-switch>
                </el-dropdown-item>
                <el-dropdown-item class="flex" command="4">
                  <div class="flex_wid">{{ $t('message.Motiontrajectorybinding') }}</div><el-switch
                    style="margin-left: 15px" v-model="userState.isBindRoad" :inactive-value="0" :active-value="1"
                    active-color="var(--main-color)" inactive-color="#838383">
                  </el-switch>
                </el-dropdown-item> -->
                <el-dropdown-item command="5">
                  <div style="
                    border-top: 1px solid #666666;
                    padding: 0px 10px;
                    color: #468383;
                  ">
                    {{ $t('message.Changepassword') }}
                  </div>
                </el-dropdown-item>
                <el-dropdown-item command="6">
                  <div style="
                    border-top: 1px solid #666666;
                    padding: 0px 10px;
                    color: red;
                  ">
                    {{ $t('message.Logoutoflogin') }}
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="bottom_right flex" @click="bindDataShow()">
            <div style="margin-right: 10px">{{ $t('message.Dataanalysis') }}</div>
            <img v-if="isDataAnalysis" src="@/assets/navigationbar/dataanalysis.png" alt="" />
            <img v-else src="@/assets/navigationbar/dataanalysis1.png" alt="" />
          </div>
        </div>
      </div>
    </el-header>
    <!-- 导航栏结束 -->
    <el-container :style="$store.state.displayArea.height">
      <el-aside :style="$store.state.displayArea.leftWidth + $store.state.displayArea.height">
        <div
          :style="$store.state.displayArea.leftWidth + $store.state.displayArea.height + $store.state.displayArea.topTow">
          <!-- 调度对讲群组 -->
          <group v-if="$store.state.navId == 1" ref="group" :navId="$store.state.navId"></group>
          <!-- 电子围栏 -->
          <enclosure v-if="$store.state.navId == 2"></enclosure>
          <!-- 实时视频 -->
          <!-- <realtime-video v-if="$store.state.navId == 3"></realtime-video> -->
          <!-- 历史记录 -->
          <sidebar v-if="$store.state.navId == 4" @sidebarTab="sidebarTab"></sidebar>
        </div>
      </el-aside>
      <!-- 右侧地图 -->
      <el-main :style="$store.state.displayArea.rightWidth + $store.state.displayArea.height">
        <!-- 数据分析 -->
        <dataAnalysis :userState="userState" :isDataAnalysis="isDataAnalysis"></dataAnalysis>
        <router-view v-if="$route.name != 'Home'" />
        <videoLntercom ref="videoLntercom"></videoLntercom>
        <dhPopup ref="dhPopup"></dhPopup>
      </el-main>
    </el-container>
    <!-- 修改密码弹出框 -->
    <popup ref="popupPassword"></popup>
    <!-- 消息提示 -->
  </el-container>
</template>

<script src=""></script>
<script>
import axios from "axios";
import qs from "qs";
import group from "@/components/group.vue";
import mapcustomfence from "@/components/mapcustomfence.vue";
import dataAnalysis from "@/components/dataAnalysis.vue";
import sidebar from "@/components/history/sidebar.vue";
import popup from "@/components/popup.vue";
import enclosure from "@/components/enclosure/enclosure.vue";
import realtimeVideo from "@/components/realtimeVideo/index.vue";
import videoLntercom from "@/components/dispatchLntercom/videoLntercom.vue";
import dhPopup from "@/components/dispatchLntercom/dhPopup.vue";
import { _debounce } from "@/method/utils.js";
import {
  getUserInfo,
  updateUserInfo,
  queryUserService
} from "@/administration/dispatchLntercom.js";
import EventBus from "../eventBus/eventBus.js";
import beasconfig from "@/api/beasconfig.js"
const videoUrlapi = beasconfig.videoUrlapi;
export default {
  components: {
    group: group,
    mapcustomfence,
    popup,
    dataAnalysis,
    realtimeVideo,
    enclosure,
    sidebar,
    videoLntercom,
    dhPopup
  },
  data() {
    return {
      tabArray: [
        //导航栏数据
        {
          id:1,
          lapse: "el-icon-s-marketing",
          name: this.$t('message.dispatchIntercom'),
          good: "/intercom/baiduMap",
          routerName:'baiduMap'
        },
        {
          id:2,
          lapse: "el-icon-s-marketing",
          name: this.$t('message.electricfence'),
          good: "/enclosure/enclosureMap",
          routerName:'enclosureMap'
        },
        // {
        //     id:3,
        //   lapse: "el-icon-s-marketing",
        //   name: "实时视频",
        //   good: "/promotion/videoInfo"
        // },
        {
          id:4,
          lapse: "el-icon-s-marketing",
          name: this.$t('message.historyrecord'),
          good: "/history/recording", //recording
          routerName:'recording'
        }
      ],
      navId: 1, //导航栏下标
      huxing: [{ namekey: "首页", keymot: "/dashboard" }], //父传子数据
      isDataAnalysis: false, //数据分析
      userState: {
        isBindRoad: 0, // 是否绑路 0 否 1是
        sosVoice: 0, //sos声音开关
        speechRecognition: 0, //语音识别
        fullDuplex: 0, //全双工
        fullDuplexAudioTime: 0,
        isTalkButton: 0, //对讲按键显示
        enclosureVoice: 0 //围栏声音
      },
      recordNum:0   //记录次数
    };
  },
  created() {
    // 创建后
    // return
    let that = this;
    that.$store.state.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    let navId = JSON.parse(sessionStorage.getItem("navId"));
    if (navId) {
      that.$store.state.navId = navId;
    }
    if(that.$store.state.navId == 1 && that.$route.name == "Home"){   //第一次进入默认跳到百度地图
      that.$router.push(that.tabArray[0].good)
    }
    that.getUserInfo();
    that.init();
    var isKeyDown = "";
    //按空格的事件
    var time = 0;
    var set = null;
    document.onkeydown = function(e) {
      //键盘按下去的事件
      let key = window.event.keyCode;
      if (e.target.nodeName == "TEXTAREA" || e.target.nodeName == "INPUT") {
        //是否在输入框

        return;
      }
      if (key == 32) {
        that.$store.state.prohibitRoll = true; //禁止滚动条滚动
        if (that.$store.state.isBroadcast) {
          //是否有人发送广播
          return;
        }
        if (that.$store.state.pttIntercom) {
          //解决ptt和空格讲话冲突
          return;
        }
        if (!that.$store.state.isFocusing && !that.$store.state.pttIntercom) {
          if (time == 3) {
            if(that.$store.state.videoCallInfo.callType != 0 || that.$store.state.voiceCallInfo.callType != 0){
              if(that.recordNum == 0){
                if(that.$store.state.videoCallInfo.callType != 0){
                  that.$MessageWarning(this.$t('message.message.Videocallinprogress'));
                }
                if(that.$store.state.voiceCallInfo.callType != 0){
                  that.$MessageWarning(this.$t('message.message.Voicecallinprogress'));
                }
                that.recordNum = 1;
              }
              return
            }
            if (that.$store.state.audio_enable == 1) {
              EventBus.$emit("speechType", 1);
              that.$store.state.pttIntercom = true;
              that.$store.state.isTextBb = false;
            } else {
              that.$store.state.pttIntercom = true;
              that.$MessageWarning(this.$t('message.message.Youhavebeenclosedforspeaking'));
            }
          }
          time++;
          // 键盘已暗下去iskeydown就等于onkeydown
          isKeyDown = "onkeydown";
        }
      }
    };
    document.onkeyup = function(e) {
      // 如果离开了空格 那就将计时器清空 iskeydown状态还原 time还原
      // if (e.target.nodeName == 'TEXTAREA' || e.target.nodeName == 'INPUT') {      //是否在输入框

      //   return;
      // };
      let key = window.event.keyCode;
      if (key == 32) {
        that.recordNum = 0;
        that.$store.state.prohibitRoll = false; //禁止滚动条滚动
        if (that.$store.state.isBroadcast) {
          //是否有人发送广播

          return;
        }
        if(that.$store.state.audio_enable != 1){
          that.$store.state.pttIntercom = false;
          
          time = 0;
           return
        }
        if (that.$store.state.pttIntercom) {
          isKeyDown = "";
          if (time >= 3) {
            EventBus.$emit("speechType", 2);
            that.$store.state.pttIntercom = false;
          }
          time = 0;
        }
      }
    };
  },
  beforeDestroy(){
      // EventBus.$off();
      this.$store.state.videoToken = null;
      this.$store.state.recordAddRegionNum = 0;
      window.removeEventListener("popstate",() => {
            history.pushState(null, null, document.URL)
        }, false);
      window.onbeforeunload = null;
      EventBus.$off("openPhone");
      EventBus.$off("openPhoneCall");
    },
  mounted() {
    let that = this;
    // window.addEventListener('online',  function(){
    //  // 网络由异常到正常时触发
    // });
    window.addEventListener('offline', function(){
     // 网络由正常常到异常时触发
      that.messageTips();
    });
    history.pushState(null, null, document.URL)
    window.addEventListener('popstate', () => {
        history.pushState(null, null, document.URL)
    })
    window.onbeforeunload = function(e) {
      e = e || window.event;
      if (e) {
        sessionStorage.removeItem("openArray");
        sessionStorage.removeItem("checkedArray");
        e.returnValue = that.$t('message.message.tctemporarygroup');
      }
      EventBus.$emit("changePassword",1);
      return that.$t('message.message.tctemporarygroup');
    };
    document.addEventListener("visibilitychange", function() {
      //浏览器离开页面
      if (document.visibilityState == "hidden") {
        //切离该页面时执行
        if (that.$store.state.userSpeech.isShow) {
          that.$store.state.userSpeech.isShow = false;
          that.$store.state.userSpeech.isBool = false;
        }
      }
      // else if (document.visibilityState == "visible") {
      //   //切换到该页面时执行
      //   console.log(123);
      // }
    });
  },
  destroyed() {
    // EventBus.$off("screenData");
  },
  methods: {
    // 方法集合
    init() {
      let that = this;
      EventBus.$on("openPhone", function() {
        that.$refs.videoLntercom.openPhone(1);
      });

      EventBus.$on("openPhoneCall", function(type) {  //打开语音通话
        that.$refs.dhPopup.openPhone(type);
      });
    },
    handleCommand(e) {
      //下拉菜单
      if (e < 5) {
        let data = {
          uId: this.$store.state.userInfo.id,
          isBindRoad: this.userState.isBindRoad,
          sosVoice: this.userState.sosVoice,
          speechRecognition: this.userState.speechRecognition,
          fullDuplex: this.userState.fullDuplex,
          isTalkButton: this.userState.isTalkButton,
          enclosureVoice: this.userState.enclosureVoice
        };
        updateUserInfo(data).then(res => {
          console.log(res);
        });
      }
      if (e == 5) {
        // 修改密码
        this.$refs.popupPassword.dialogModifyPasswode = true;
      }
      if (e == 6) {
        this.signOutLogin();
      }
    },
    //退出登录
    signOutLogin() {
      let that = this;
      that
        .$confirm(this.$t('message.message.Doyouwanttologout'), this.$t('message.message.prompt'), {
          confirmButtonText: this.$t('message.confirm'),
          cancelButtonText: this.$t('message.cancel'),
          type: "warning"
        })
        .then(() => {
            EventBus.$emit("changePassword");
        })
        .catch(() => {});
    },
    // 获取用户信息
    getUserInfo() {
      let that = this;
      getUserInfo({
        uId: that.$store.state.userInfo.id
      }).then(res => {
        if (res.data.code == 200) {
          // console.log(res)
          that.userState = res.data.data;
          sessionStorage.setItem("userState", JSON.stringify(res.data.data));
          that.$store.state.userState = res.data.data;
          that.$store.state.userState.isLoading = true;
          // that.getVideoToken();
        }else{
          that.$MessageWarning(res.data.msg);
        }
      });
      queryUserService({      //用户服务
                uId: that.$store.state.userInfo.id,
                uids: JSON.stringify(that.$store.state.userInfo.User_ID)
            }).then(res => {
                let userInfo = res.data.data[0];
                if (res.data.code == 200) {
                   that.$store.state.userService = userInfo;
                } else {
                    that.$MessageWarning(res.data.msg);
                }
            });
    },
    changeSwitch(bool, type) {
      //switch切换状态
      if (type == 1) {
        this.userState.isTalkButton = bool ? 1 : 0;
      } else if (type == 2) {
        this.userState.sosVoice = bool ? 1 : 0;
      } else if (type == 3) {
        this.userState.enclosureVoice = bool ? 1 : 0;
      } else {
        this.userState.isBindRoad = bool ? 1 : 0;
      }
    },
    bindDataShow() {
      //数据分析显示隐藏
      this.isDataAnalysis = !this.isDataAnalysis;
    },
    changeTab(row) {
      //顶部导航栏切换
      if (this.$store.state.navId == row.id) {
        return;
      }
      this.$store.state.navId = row.id;
      sessionStorage.setItem("navId", row.id);
      this.$router.push(row.good);
      this.$store.state.selectedInfo = [];
      this.$store.state.efId = 0;
      sessionStorage.removeItem("openArray");
      sessionStorage.removeItem("checkedArray");
    },
    sidebarTab(index) {
      //历史记录左侧切换
      this.$refs.history.historyTab(index);
    },
    getVideoToken(){   //获取视频token 
          let that = this;
          let time = that.$moment(new Date()).valueOf();
          let ukey = that.$md5(`${that.$store.state.userInfo.User_ID}${that.$md5('1')}${JSON.stringify(time)}`)
          let parmas = {
              uid:that.$store.state.userInfo.User_ID,
              time:time,
              ukey:ukey
            };
            axios.post(`${videoUrlapi}-${that.userState.server}/rtc/auth/token`, qs.stringify(parmas),
            {
              headers: beasconfig.tokenSl
            }
            )
                .then(res => {
                    if (res.data.code == 200) {
                        // console.log(res.data.data.token)
                        that.$store.state.videoToken = res.data.data.token;
                    }
              })
    },
    messageTips:_debounce(function(){
            this.$MessageWarning(this.$t('message.message.Nonetworklink'));
            EventBus.$emit("changePassword");
     },1000)
  },
  watch: {

  }
};
</script>
<style lang="less" scoped>
.el-container {
  width: 100%;
  height: 100vh;
  overflow: hidden;

  :focus-visible {
    outline: -webkit-focus-ring-color auto 0px !important;
  }

  .el-header {
    width: 100%;
    // background-color: #b3c0d1;
    // padding: 0px 10px 0px 20px !important;
    color: #333;
    text-align: center;
    background-color: #343434;
    border-bottom: 1px solid var(--main-color);
    overflow: hidden;

    &>div {
      width: 100%;
      // padding: 0px 10px 0px 20px !important;
      position: absolute;
      top: 0px;
      left: 0px;
      box-shadow: 2px 2px 5px #000;
      z-index: 2;
    }

    .logo {
      width: 114px;
      height: 59px;
      margin-left: 40px;
      text-align: center;

      .img {
        width: 100%;
        height: 100%;
      }
    }

    .tab {
      // width: calc(100vw - 190px - 300px);
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      font-size: 14px;
      letter-spacing: 2px;
      margin-top: 20px;
      background-color: #343434;
      // justify-content: flex-end;
      // margin-right: 150px;

      .tab_box {
        min-width: 100px;
        margin-right: 50px;
        cursor: pointer;
      }

      .tab_box:last-child {
        margin-right: 0px;
      }

      .tab_color {
        color: var(--main-color-tow);
        border: 1px solid var(--main-color-tow) !important;
        padding: 5px 10px;
        border-radius: 50px;
      }
    }

    .bottom {
      // width: 300px;
      margin-top: 20px;
      margin-right: 10px;

      .bottom_box {
        width: calc(320px - 133px);

        .el-dropdown {
          width: 100%;
        }

        .el-dropdown-link {
          cursor: pointer;
          color: #fff;
          font-size: 14px;

          .dropdown_xh {
            width: 100%;
            height: 100%;
            border-top: 1px solid #ccc;
          }

          .img {
            width: 16px;
            height: 17px;
            margin-right: 5px;
          }

          div {
            // max-width: calc(300px - 120px - 30px);
            // flex: 1;
            // text-align: left;
            max-width: 140px;
            white-space: nowrap;
            /*内容超宽后禁止换行显示*/
            overflow: hidden;
            /*超出部分隐藏*/
            text-overflow: ellipsis;
            /*文字超出部分以省略号显示*/
          }
        }

        .el-icon-arrow-down {
          font-size: 12px;
        }
      }

      .bottom_right {
        // width: 107px;
        height: 26px;
        line-height: 26px;
        text-align: center;
        justify-content: center;
        font-size: 14px;
        margin-left: 17px;
        color: #ffffff;
        // background: url(../assets/navigationbar/yj.png);
        // background-size: 100% 100%;
        background-color: var(--main-color);
        border-radius: 20px;
        cursor: pointer;
        padding: 0px 15px;
      }
    }
  }

  /deep/.el-aside {
    overflow: hidden !important;
    background-color: #d3dce6;
    color: #333;
    text-align: center;
  }

  .el-aside>div {
    width: 100%;
    height: 100%;
    overflow: hidden;
    box-shadow: 4px 0px 8px 0px #000000;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 1;
  }

  .el-main {
    overflow: hidden;
    background-color: #e9eef3;
    color: #333;
    text-align: center;
    // line-height: 160px;
    position: relative;
    padding: 0px !important;
  }
}

.flex {
  display: flex;
  align-items: center;
}

.baidu-api {
  width: 100%;
  height: 100%;
}

::-webkit-scrollbar {
  width: 0px;
  height: 100%;
  background-color: #ccc;
}

::-webkit-scrollbar-track {
  width: 0px;
  background-color: #4d4d4d;
}

::-webkit-scrollbar-thumb {
  background-color: #727171;
}


.flex_wid {
  flex: 1;
  text-align: left;
}
</style>
